.digits-verification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.digits-verification-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}

.number-container {
  display: flex;
  gap: 10px;
  margin-bottom: 50px;
}

.input-container {
  position: relative;
}

.input-container input {
  text-align: center;
  display: block;
  outline: none;
  border: 0;
  color: #000;
  background-color: transparent;
  width: 40px;
  position: relative;
  font-weight: bold;
  font-size: 1.5625rem;
}

.input-container::after {
  content: "";
  background-color: #ebebeb;
  width: 100%;
  height: 10px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  border-radius: 8px;
}

.number {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  width: 400px;
  max-width: 100%;
  margin: 0 auto;
}

.number-text {
  background-color: #e9e9e9;
  width: 120px;
  height: 120px;
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: bold;
  color: #3b3b3b;
  cursor: pointer;
  user-select: none;
}
.remove-btn{
  background-color: #b80101;
  color: var(--white-color);
}
.number-text:hover,
.number-text:focus {
  background-color: var(--primary-color);
  color: #fff;
}

.remove-btn:hover,
.remove-btn:focus {
  background-color: #cb0404;
  color: var(--white-color);
}

@media (max-width: 576px) {
  .number-text {
    width: 100px;
    height: 100px;
  }
}
@media (max-width: 480px) {
  .number-container {
    gap: 3px;
  }
  .number {
    width: 300px;
  }
  .number-text {
    width: 80px;
    height: 80px;
  }
  .input-container input {
    width: 35px;
  }
  .number-container {
    flex-wrap: wrap;
  }
}
@media (max-width: 320px) {
  .input-container input {
    width: 30px;
  }
  .number-text {
    width: 60px;
    height: 60px;
  }
  .input-container input {
    width: 25px;
  }
}
