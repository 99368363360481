.form-container {
  width: 100%;
  padding-bottom: 30px;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 23px;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
}

.input-label {
  text-transform: capitalize;
  font-weight: 700;
}

.input-field {
  border: none;
  outline: none;
  background-color: #efefef;
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  font-size: 18px;
}

.btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.signature-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.signature-canvas {
  border: 1px solid #efefef;
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap:10px;
  margin-bottom:13px}

.checkbox-wrapper input {
  display: none;
}

.checkbox-wrapper label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.checkbox-icon {
  width: 32px;
  height: 32px;
  font-size: 25px;
  color: var(--white-color);
  background-color: var(--white-color);
  border-radius: 50%;
  border: 2px solid var(--text-color);
  transition: background-color 0.3s;
}

.checkbox-icon.checked {
  border: 0;
  color: var(--primary-color);
  font-size: 32px;
}

@media (max-width: 600px) {
  .flex {
    flex-direction: column;
    gap: 15px;
  }

  .signature-canvas {
    height: 150px;
  }
}

@media (max-width: 480px) {
  .input-label {
    font-size: 0.9rem;
  }

  .input-field {
    font-size: 0.9rem;
    padding: 8px;
  }

  .signature-canvas {
    height: 120px;
  }
}
