.home {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding-top: 20vh;
}

.header-title h1 {
  color: var(--white-color);
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 4.6875rem;
  font-weight: bold;
}

.header-title h2 {
  color: var(--white-color);
  text-transform: capitalize;
  font-size: 3.625rem;
  font-weight: bold;
  text-align: center;
}

.svg-container {
  min-height: 600px;
  width: 100%;
  position: relative;
  background-color: var(--primary-color);
}

.svg-container svg {
  position: absolute;
  bottom: -2px;
  left: 0;
}

.home-content {
  position: absolute;
  top: 370px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.img-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-container img {
  width: 600px;
  max-width: 100%;
  height: auto;
}

.arrow-icon {
  margin-top: 50px;
  background-color: var(--primary-color);
  color: var(--white-color);
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.arrow-icon:hover {
  background-color: var(--dark-blue);
}

@media (max-width: 1024px) {
  .header-title {
    padding-top: 5vh;
  }

  .home-content {
    top: 280px;
  }
  .svg-container {
    min-height: 600px;
  }
}

@media (max-width: 834px) {
  .svg-container {
    min-height: 600px;
  }
  .home-content {
    top: 381px;
  }
  .header-title {
    padding-top: 15vh;
  }
}

@media (max-width: 767px) {
  .header-title h2 {
    font-size: 2.5rem;
  }
  .home-content {
    top: 300px;
  }
}

@media (max-width: 600px) {
  .header-title h1 {
    font-size: 2.5rem;
  }
  .header-title h2 {
    font-size: 1.875rem;
  }
}

@media (max-width: 415px) {
  .img-container img {
    width: 400px;
  }
  .home-content {
    top: 355px;
  }

  .header-title {
    padding-top: 25vh;
  }
  .arrow-icon {
    width: 90px;
    height: 90px;
  }
  .header-title h2 {
    font-size: 25px;
  }
}
@media (max-width: 375px) {
  .header-title h2 {
    font-size: 1.375rem;
  }
  .home-content {
    top: 240px;
  }
  .img-container img {
    width: 600px;
  }
  .header-title {
    padding-top: 15vh;
  }
}
