.heading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.heading-wrapper h4 {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: var(--primary-color);
  font-weight: 900;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 0.9375rem;
}
.heading-text {
  color: var(--primary-color);
  font-size: 2.5rem;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .heading-text {
    font-size: 2.1875rem;
  }
}
@media (max-width: 652px) {
  .heading-text {
    font-size: 1.9375rem;
  }
}
@media (max-width: 576px) {
  .heading-text {
    font-size: 1.5625rem;
  }
}

@media (max-width: 320px) {
  .heading-text {
    font-size: 1.375rem;
  }
}
