header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 240px;
  padding-inline: 30px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  gap: 20px;
}

.brand-logo {
  width: 180px;
  height: auto;
  max-width: 100%;
}

.header-text {
  color: var(--white-color);
  text-transform: uppercase;
  font-size: 64px;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
}
@media (max-width: 679px) {
  header {
    height: 200px;
  }
}
@media (max-width: 576px) {
  .header-text {
    font-size: 55px;
  }
  .brand-logo {
    width: 150px;
  }
}
@media (max-width: 480px) {
  header {
    height: 200px;
  }
  .header-text {
    font-size: 50px;
  }
  .brand-logo {
    width: 140px;
  }
}
@media (max-width: 400px) {
  .header-text {
    font-size: 40px;
  }
  .brand-logo {
    width: 150px;
  }
  header {
    height: 220px;
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
}
@media (max-width: 320px) {
  header {
    justify-content: center;
  }
}
/* Brand Header */
.brand-header {
  background-color: var(--primary-color);
  width: 100%;
  height: 240px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}
.brand-header h1 {
  color: var(--white-color);
  text-transform: uppercase;
  font-size: 64px;
  font-weight: bold;
}

@media (max-width: 576px) {
  .brand-header h1 {
    font-size: 3.125rem;
  }
}
@media (max-width: 480px) {
  .brand-header h1 {
    font-size: 2.5rem;
  }
}
@media (max-width: 320px) {
  .brand-header h1 {
    font-size: 1.875rem;
  }
}
