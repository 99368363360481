
.choose-brand-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.choose-brand-content {
  padding-bottom: 20px;
}
.brands {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.brand-item {
  cursor: pointer;
}
.brands {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.brands img {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .brands {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .brands {
    grid-template-columns: 1fr;
  }
  .choose-brand-content {
    padding-inline: 10px;
  }
}

