@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");
:root {
  --primary-color: #144798;
  --dark-blue: #154169;
  --secondary-color: #fbfbfb;
  --white-color: #fff;
  --text-color: #000;
}
body {
  background-color: var(--secondary-color);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Tajawal", sans-serif;
}
h1 {
  font-family: "Times New Roman", Times, serif;
}
.flex-row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-center {
  text-align: center;
}

.content {
  width: 95%;
  margin: auto;
  position: relative;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"] {
  appearance: textfield;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner-icon {
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.back-btn {
  margin-right: auto;
  text-transform: uppercase !important;
  font-weight: 500 !important;
  z-index: 10;
  font-size: 1rem !important;
  padding-inline: 15px !important;
}
