.btn {
  display: block;
  background-color: var(--primary-color);
  padding-inline: 60px;
  padding-block: 15px;
  margin-top: 20px;
  border-radius: 45px;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  -o-border-radius: 45px;
  text-align: center;
  width: fit-content;
  outline: none;
  border: none;
  color: var(--white-color);
  text-transform: capitalize;
  font-size: 1.875rem;
  font-weight: bold;
  letter-spacing: 1.1px;
  cursor: pointer;
}

@media (max-width: 576px) {
  .btn {
    padding-inline: 50px;
    padding-block: 8px;
  }
  .btn a {
    font-size: 1.5625rem;
  }
}
