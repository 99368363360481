.order-complete-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding-bottom:20px ;
}
.responsive-img {
  max-width: 100%;
  display: block;
}
